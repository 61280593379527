.container-ajustesCot{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.regresar{
margin-left: auto;
margin-top: auto;
padding: 10px;
}

.enConstruccion{
    display: flex;
    height: 73vh;

}

.item{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    text-decoration: none;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    color: #27374d;
    border: none;
    background: #dde6ed;
}

.item:hover{
    background: #27374d;
    color: #dde6ed;
}

.contenedor-columnas1 {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
    padding: 7px 0 7px 0;
    border-bottom: 0.5px solid #dde6ed;
    margin-bottom: 10px;
  }