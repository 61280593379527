.container-ajustesPrecios {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .regresar {
    /* align-items: center; */
    /* justify-content: center; */
    /*justify-items: center;
  margin-top: auto;
   */
    padding: 10px;
    /* border-bottom: solid 1px #dde6ed;
  width: 100%; */
  }
  
  .enConstruccion {
    display: flex;
    height: 73vh;
  }
  
  .item {
    display: flex;
    align-items: center;
    gap: 0px;
    font-size: 0.8rem;
    text-decoration: none;
    font-weight: 600;
    padding: 7px;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    color: #27374d;
    border: none;
    background: #dde6ed;
  }
  
  .item:hover {
    background: #27374d;
    color: #dde6ed;
  }
  
  .columnaIzquierda {
    display: flex;
    flex-direction: column;
    width: 42%;
    margin: 0;
    justify-content: center;
  }
  .columnaDerecha {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 56%;
    /* background: rebeccapurple; */
    margin-left: 2%;
    font-size: 0.8rem;
  }
  .columnaDerecha1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 76%;
    /* background-color: royalblue; */
    margin-left: 2%;
    font-size: 0.8rem;
  }
  
  .contenedor-columnas {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .contenedor-columnas1 {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
    padding: 7px 0 7px 0;
    border-bottom: 0.5px solid #dde6ed;
    margin-bottom: 10px;
  }
  
  .button {
    /* display: flex;
      align-items: center; */
    gap: 0px;
    font-size: 1rem;
    /* text-decoration: none; */
    /* padding: 7px; */
    /* border-radius: 5px; */
    /* transition: 0.3s ease-in-out; */
    /* color: #27374d; */
    border: none;
    background: #fff;
    width: 100%;
    text-align: left;
    /* justify-content: start; */
  }
  /* .contene-alineado{
      display: flex;
      flex-direction: row;
      border: none;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  } */
  .divButton {
    border: none;
    float: left;
    width: 85%;
    margin-left: 5%;
  }
  
  .divFlecha {
  }
  
  .row-col-izq-up {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    vertical-align: middle;
  }
  .row-col-izq-up h5 {
    vertical-align: middle;
    margin: 0;
  }
  .row-col-izq-down {
    width: 100%;
    margin-top: 5vh;
  }
  .divRegresar{
    width: 24%;  
    margin-left:1%;
    display: flex;
    flex-direction: row-reverse;
  }
