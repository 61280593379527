.content{
    width:100%;
}
.content--header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dde6ed;
    margin-top: 7px;
    padding-bottom: 2px;
}
.header--title{
    color: #526d82;
}
.header--activity{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
}
.search-box {
    background: #dde6ed;
    padding: 8px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.ubicacion {
    /* background: #dde6ed; */
    padding: 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fecha {
    /* background: #dde6ed; */
    padding: 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cont-fecha{
    padding-left: 2px;
    display: flex;
    align-items: center;
    padding-top: 2px;
}
.ubicacion h6{
    text-align: center;
}
.ubicacion .icon1, .fecha .icon1{
    color: #969393;
    font-size: 22px;
    /* cursor: pointer; */
    /* transition: 0.5s ease-in-out; */
}
.user {
    /*background: #dde6ed;*/
    padding: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.user .icon1{
    color: #969393;
    font-size: 1.3rem;
    
    /*cursor: pointer;
    transition: 0.5s ease-in-out;*/
}
.search-box input{
    border: none;
    outline: none;
    background: transparent;
    padding: 5px;
    outline: none;
}
.search-box .icon{
    color: #969393;
    font-size: 22px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}
.notify{
    background: #dde6ed;
    padding: 12px;
    border-radius: 10px;
    color: #969393;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notify .icon{
    font-size: 20px;
   /*  transition: 0.5s ease-in-out; */
}
.icon:hover{
    /** bote de basura **/
    transform: scale(1.3);
    cursor: pointer;
}
/*card container*/
.cardM--container{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 2rem;
}
.cardM{
    flex:1;
    background: #dde6ed;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    /* transition: 0.5s ease-in-out; */
}
.cardM:hover {
   /*  transform: scale(1.04); 
    cursor: pointer;*/
}
.cardM--cover{
    background: #fff;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    color: #969393;
}
.cardM--title{
    background: #ffffffc0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    color: #526d82;
    border-radius: 10px;
}
.content--interior{
    padding-top: 0px;
}

.contenedor-100porciento{
    width: 100%;
    
}
.content--header--editamars{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dde6ed;
    /* margin-top: 7px; */
    padding-bottom: 15px;
    margin-bottom: 10px;
}
.search-box-editmars .icon-1{
    color: #969393;
    font-size: 22px;
    /* cursor: pointer;
    transition: 0.5s ease-in-out; */
}
.search-box-editmars input{
    border: none;
    outline: none;
    background: transparent;
    padding: 5px;
    outline: none;
}
.search-box-editmars {
    background: #dde6ed;
    padding: 8px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.header--activity-file{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
}
.button-admin{
    border: none;
    width: 100%;
    background: #f5f7fa;
    font-size: 1rem;
    font-weight: 500;
    color: #526d82;
}