.dashboard{
  display: flex;
  gap: 20px;
}
.dashboard--content{
  background: #fff; 
  flex: 1;
  border-radius: 20px;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}
