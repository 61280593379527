.menu{
    display:flex;
    flex-direction: column;
    gap: 2.3rem;
    height: 93vh;
}
.logo{
    text-align: center;
    padding: 20px;
    color: #27374d;
    /*justify-content: center;
    align-items: center;*/
    display: flex;
    gap: 20px;
}
.logo-icon{
    font-size: 1.8rem;
}
.menu--list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.menu--list .item{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
    color: #27374d;
    border: none;
    background: #dde6ed;
}
.menu--list .item:hover{
    background: #27374d;
    color: #dde6ed;
}