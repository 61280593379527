.container-usuarios{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.regresar{
margin-left: auto;
margin-top: auto;
padding: 10px;
}

.enConstruccion{
    display: flex;
    height: 73vh;
    
}

.item{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    text-decoration: none;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    color: #27374d;
    border: none;
    background: #dde6ed;
}

.item:hover{
    background: #27374d;
    color: #dde6ed;
}
