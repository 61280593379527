.boton-login-ingreso{
    border-radius: 5px;
    color: #fff;
    background: #022a54;
    border: 2px solid #022a54; 
    padding-top:0.5rem;
    padding-bottom: 0.5rem;
}

#login-button:hover {
    color: #022a54;
    background: #fff;
    border: 2px solid #022a54; 
    transition: ease-in 0.3s;
    font-weight: 500;
   /*  margin-top:-0.5rem; */
    /* margin-bottom: 0.5rem; */
}